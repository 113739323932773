import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SharedService {
  private buttonClickSource = new Subject<void>();
  private triggerOpenSource = new Subject<void>();
  private scheduleOpenTrigger = new Subject<any>();
  private mquiOpen = new Subject<any>();

  private successfulTrigger = new BehaviorSubject<boolean>(false);
  private mquiClose = new BehaviorSubject<boolean>(false);

  buttonClick$ = this.buttonClickSource.asObservable();
  triggerOpen$ = this.triggerOpenSource.asObservable();
  scheduleOpenTrigger$ = this.scheduleOpenTrigger.asObservable();
  mquiOpen$ = this.mquiOpen.asObservable();

  successfulTrigger$ = this.successfulTrigger.asObservable();
  mquiClose$ = this.mquiClose.asObservable();

  setSuccessfulTrigger(value: boolean): void {
    this.successfulTrigger.next(value);
  }

  getSuccessfulTrigger(): boolean {
    return this.successfulTrigger.getValue();
  }

  setMquiClose(value: boolean): void {
    this.mquiClose.next(value);
  }

  getMquiClose(): boolean {
    return this.mquiClose.getValue();
  }

  emitHmeClick() {
    this.buttonClickSource.next();
  }

  emitTriggerOpen() {
    this.triggerOpenSource.next();
  }

  triggerModal(data: {
    selectedSurveyId: string;
    patientId: string;
    dayOffset: string;
    deviceSerial?: string;
  }) {
    this.scheduleOpenTrigger.next(data);
  }

  mquiModal(mquiUrl: string) {
    this.mquiOpen.next(mquiUrl);
  }
}
