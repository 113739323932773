<ng-template #adsSvgSymbolsComponent>
    <!-- SVG icon symbol library -->
    <svg aria-hidden="true" class="u-hidden-visually" version="1.1" xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink">
      <filter id="drop-shadow">
        <feGaussianBlur in="SourceAlpha" stdDeviation="2" />
        <feOffset dx="0" dy="4" result="offsetblur" />
        <feFlood flood-color="rgb(0,0,0)" flood-opacity="0.3" />
        <feComposite in2="offsetblur" operator="in" />
        <feMerge>
          <feMergeNode />
          <feMergeNode in="SourceGraphic" />
        </feMerge>
      </filter>
      <defs>
        <symbol id="c-svg--arrow-down-24" viewBox="0 0 24 24">
            <path d="M18.707 14.707l-5.999 5.999c-0 0-0.001 0.001-0.001 0.001s-0.003 0.001-0.004 0.003c-0.091 0.091-0.2 0.163-0.321 0.213-0.122 0.051-0.252 0.077-0.383 0.077s-0.26-0.027-0.383-0.077c-0.112-0.046-0.21-0.116-0.297-0.197-0.008-0.008-0.020-0.010-0.028-0.019-0-0-0-0.001-0.001-0.001l-5.999-5.999c-0.391-0.391-0.391-1.024 0-1.414s1.024-0.39 1.414 0l4.293 4.293v-13.586c0-0.552 0.448-1 1-1s1 0.448 1 1v13.585l4.293-4.293c0.391-0.39 1.024-0.39 1.414 0s0.391 1.024 0 1.414z"></path>
          </symbol>
          <symbol id="c-svg--arrow-left-24" viewBox="0 0 24 24">
            <path d="M9.293 18.707l-5.999-5.999c-0-0-0.001-0.001-0.001-0.001s-0.001-0.003-0.003-0.004c-0.091-0.091-0.163-0.2-0.213-0.321-0.051-0.122-0.077-0.252-0.077-0.383s0.027-0.26 0.077-0.383c0.046-0.112 0.116-0.21 0.197-0.297 0.008-0.008 0.010-0.020 0.019-0.028 0-0 0.001-0 0.001-0.001l5.999-5.999c0.391-0.391 1.024-0.391 1.414 0s0.39 1.024 0 1.414l-4.293 4.293h13.586c0.552 0 1 0.448 1 1s-0.448 1-1 1h-13.585l4.293 4.293c0.39 0.391 0.39 1.024 0 1.414s-1.024 0.391-1.414 0z"></path>
          </symbol>
          <symbol id="c-svg--arrow-right-24" viewBox="0 0 24 24">
            <path d="M14.707 5.293l5.999 5.999c0 0 0.001 0.001 0.001 0.001s0.001 0.003 0.003 0.004c0.091 0.091 0.163 0.2 0.213 0.321 0.051 0.122 0.077 0.252 0.077 0.383s-0.027 0.26-0.077 0.383c-0.046 0.112-0.116 0.21-0.197 0.297-0.008 0.008-0.010 0.020-0.019 0.028-0 0-0.001 0-0.001 0.001l-5.999 5.999c-0.391 0.391-1.024 0.391-1.414 0s-0.39-1.024 0-1.414l4.293-4.293h-13.586c-0.552 0-1-0.448-1-1s0.448-1 1-1h13.585l-4.293-4.293c-0.39-0.391-0.39-1.024 0-1.414s1.024-0.391 1.414 0z"></path>
          </symbol>
          <symbol id="c-svg--arrow-up-24" viewBox="0 0 24 24">
            <path d="M5.293 9.293l5.999-5.999c0-0 0.001-0.001 0.001-0.001s0.003-0.001 0.004-0.003c0.091-0.091 0.2-0.163 0.321-0.213 0.122-0.051 0.252-0.077 0.383-0.077s0.26 0.027 0.383 0.077c0.112 0.046 0.21 0.116 0.297 0.197 0.008 0.008 0.020 0.010 0.028 0.019 0 0 0 0.001 0.001 0.001l5.999 5.999c0.391 0.391 0.391 1.024 0 1.414s-1.024 0.39-1.414 0l-4.293-4.293v13.586c0 0.552-0.448 1-1 1s-1-0.448-1-1v-13.585l-4.293 4.293c-0.391 0.39-1.024 0.39-1.414 0s-0.391-1.024 0-1.414z"></path>
          </symbol>
          <symbol id="c-svg--pause-24" viewBox="0 0 24 24">
            <path d="M6 19h4v-14h-4v14zM14 5v14h4v-14h-4z"></path>
          </symbol>
          <symbol id="c-svg--pause-circle-fill-24" viewBox="0 0 24 24">
            <path d="M12 2c-5.52 0-10 4.48-10 10s4.48 10 10 10 10-4.48 10-10-4.48-10-10-10zM11 16h-2v-8h2v8zM15 16h-2v-8h2v8z"></path>
          </symbol>
          <symbol id="c-svg--pause-circle-outline-24" viewBox="0 0 24 24">
            <path d="M9 16h2v-8h-2v8zM12 2c-5.52 0-10 4.48-10 10s4.48 10 10 10 10-4.48 10-10-4.48-10-10-10zM12 20c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zM13 16h2v-8h-2v8z"></path>
          </symbol>
          <symbol id="c-svg--play-arrow-24" viewBox="0 0 24 24">
            <path d="M8 5v14l11-7z"></path>
          </symbol>
          <symbol id="c-svg--play-circle-fill-24" viewBox="0 0 24 24">
            <path d="M12 2c-5.52 0-10 4.48-10 10s4.48 10 10 10 10-4.48 10-10-4.48-10-10-10zM10 16.5v-9l6 4.5-6 4.5z"></path>
          </symbol>
          <symbol id="c-svg--play-circle-outline-24" viewBox="0 0 24 24">
            <path d="M10 16.5l6-4.5-6-4.5v9zM12 2c-5.52 0-10 4.48-10 10s4.48 10 10 10 10-4.48 10-10-4.48-10-10-10zM12 20c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"></path>
          </symbol>
          <symbol id="c-svg--chevron-first-24" viewBox="0 0 24 24">
            <path d="M10.050 11.293l8.485-8.485c0.39-0.39 1.024-0.39 1.414 0s0.39 1.024 0 1.414l-7.778 7.778 7.778 7.778c0.39 0.39 0.39 1.024-0 1.414s-1.024 0.39-1.414 0l-8.485-8.485c-0.39-0.39-0.39-1.024 0-1.414zM4.050 12.707l8.485 8.485c0.39 0.39 1.024 0.39 1.414 0s0.391-1.024 0-1.414l-7.778-7.778 7.778-7.778c0.39-0.39 0.39-1.024 0-1.414s-1.024-0.39-1.414-0l-8.485 8.485c-0.39 0.39-0.39 1.024 0 1.414z"></path>
          </symbol>
          <symbol id="c-svg--chevron-last-24" viewBox="0 0 24 24">
            <path d="M13.95 12.707l-8.485 8.485c-0.39 0.39-1.024 0.39-1.414-0s-0.39-1.024 0-1.414l7.778-7.778-7.778-7.778c-0.39-0.39-0.39-1.024 0-1.414s1.024-0.39 1.414 0l8.485 8.485c0.39 0.39 0.39 1.024 0 1.414zM19.95 11.293l-8.485-8.485c-0.39-0.39-1.024-0.39-1.414 0s-0.391 1.024-0 1.414l7.778 7.778-7.778 7.778c-0.39 0.39-0.39 1.024 0 1.414s1.024 0.39 1.414 0l8.485-8.485c0.39-0.39 0.39-1.024 0-1.414z"></path>
          </symbol>
          <symbol id="c-svg--chevron-down-24" viewBox="0 0 24 24">
            <path d="M21.192 7.050c0.39 0.39 0.39 1.024 0 1.414l-8.485 8.485c-0.39 0.39-1.024 0.39-1.414 0l-8.485-8.485c-0.39-0.39-0.39-1.024 0-1.414s1.024-0.39 1.414 0l7.778 7.778 7.778-7.778c0.39-0.39 1.024-0.39 1.414 0z"></path>
          </symbol>
          <symbol id="c-svg--chevron-left-24" viewBox="0 0 24 24">
            <path d="M16.95 21.192c-0.39 0.39-1.024 0.39-1.414 0l-8.485-8.485c-0.39-0.39-0.39-1.024 0-1.414l8.485-8.485c0.39-0.39 1.024-0.39 1.414 0s0.39 1.024 0 1.414l-7.778 7.778 7.778 7.778c0.39 0.39 0.39 1.024-0 1.414z"></path>
          </symbol>
          <symbol id="c-svg--union-24" viewBox="0 0 24 24">
            <path xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd" d="M7.86383 3.42607L9.92579 1.3641L10.6329 2.07121L9.9258 1.3641C10.2315 1.05844 10.646 0.886719 11.0783 0.886719C11.5106 0.886719 11.9251 1.05844 12.2308 1.3641L11.7062 1.88873L12.2308 1.3641L15.7493 4.88256C16.0549 5.18822 16.2267 5.60279 16.2267 6.03507C16.2267 6.46734 16.0549 6.88191 15.7493 7.18757L6.30086 16.636C6.14951 16.7873 5.96983 16.9074 5.77208 16.9893C5.57433 17.0712 5.36239 17.1134 5.14835 17.1134H1.62989C1.19762 17.1134 0.783051 16.9417 0.477385 16.636C0.17172 16.3303 0 15.9157 0 15.4835V11.965C0 11.751 0.0421584 11.539 0.124068 11.3413C0.205978 11.1435 0.326035 10.9639 0.477385 10.8125L7.83894 3.45096C7.84301 3.44675 7.84712 3.44256 7.85128 3.4384C7.85544 3.43425 7.85962 3.43013 7.86383 3.42607ZM8.55849 5.55983L2 12.1183V15.1134H4.99504L11.5535 8.55488L8.55849 5.55983ZM12.9678 7.14066L14.0733 6.03507L11.0783 3.04002L9.97271 4.14562L12.9678 7.14066Z" fill="#006DB7"/>
          </symbol>
          <symbol id="c-svg--chevron-right-24" viewBox="0 0 24 24">
            <path d="M7.050 2.808c0.39-0.39 1.024-0.39 1.414 0l8.485 8.485c0.39 0.39 0.39 1.024 0 1.414l-8.485 8.485c-0.39 0.39-1.024 0.39-1.414-0s-0.39-1.024 0-1.414l7.778-7.778-7.778-7.778c-0.39-0.39-0.39-1.024 0-1.414z"></path>
          </symbol>
          <symbol id="c-svg--chevron-up-24" viewBox="0 0 24 24">
            <path d="M2.808 16.95c-0.39-0.39-0.39-1.024 0-1.414l8.485-8.485c0.39-0.39 1.024-0.39 1.414 0l8.485 8.485c0.39 0.39 0.39 1.024-0 1.414s-1.024 0.39-1.414 0l-7.778-7.778-7.778 7.778c-0.39 0.39-1.024 0.39-1.414-0z"></path>
          </symbol>
          <symbol id="c-svg--top-circle-outline-24" viewBox="0 0 24 24">
            <path d="M7.293 10.293l3.999-3.999c0-0 0.001-0.001 0.001-0.001s0.003-0.001 0.004-0.003c0.091-0.091 0.2-0.163 0.321-0.213 0.122-0.051 0.252-0.077 0.383-0.077s0.26 0.027 0.383 0.077c0.112 0.046 0.21 0.116 0.297 0.197 0.008 0.008 0.020 0.010 0.028 0.019 0 0 0 0.001 0.001 0.001l3.999 3.999c0.391 0.391 0.391 1.024 0 1.414s-1.024 0.39-1.414 0l-2.293-2.293v7.586c0 0.552-0.448 1-1 1s-1-0.448-1-1v-7.585l-2.293 2.293c-0.391 0.39-1.024 0.39-1.414 0s-0.391-1.024 0-1.414zM12 2c-5.52 0-10 4.48-10 10s4.48 10 10 10 10-4.48 10-10-4.48-10-10-10zM12 20c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"></path>
          </symbol>
          <symbol id="c-svg--top-circle-fill-24" viewBox="0 0 24 24">
            <path d="M12 22.008c5.527 0 10.008-4.481 10.008-10.008s-4.481-10.008-10.008-10.008-10.008 4.481-10.008 10.008 4.481 10.008 10.008 10.008zM7.293 10.293l3.999-3.999c0-0 0.001-0.001 0.001-0.001s0.003-0.001 0.004-0.003c0.091-0.091 0.2-0.163 0.321-0.213 0.122-0.051 0.252-0.077 0.383-0.077s0.26 0.027 0.383 0.077c0.112 0.046 0.21 0.116 0.297 0.197 0.008 0.008 0.020 0.010 0.028 0.019 0 0 0 0.001 0.001 0.001l3.999 3.999c0.391 0.391 0.391 1.024 0 1.414s-1.024 0.39-1.414 0l-2.293-2.293v7.586c0 0.552-0.448 1-1 1s-1-0.448-1-1v-7.585l-2.293 2.293c-0.391 0.39-1.024 0.39-1.414 0s-0.391-1.024 0-1.414z"></path>
          </symbol>
          <symbol id="c-svg--download-24" viewBox="0 0 24 24">
            <path d="M16.707 13.707l-3.999 3.999c-0 0-0.001 0.001-0.001 0.001s-0.003 0.001-0.004 0.003c-0.091 0.091-0.2 0.163-0.321 0.213-0.122 0.051-0.252 0.077-0.383 0.077s-0.26-0.027-0.383-0.077c-0.112-0.046-0.21-0.116-0.297-0.197-0.008-0.008-0.020-0.010-0.028-0.019-0-0-0-0.001-0.001-0.001l-3.999-3.999c-0.391-0.391-0.391-1.024 0-1.414s1.024-0.39 1.414 0l2.293 2.293v-7.586c0-0.552 0.448-1 1-1s1 0.448 1 1v7.585l2.293-2.293c0.391-0.39 1.024-0.39 1.414 0s0.391 1.024 0 1.414zM12 2c-5.52 0-10 4.48-10 10s4.48 10 10 10 10-4.48 10-10-4.48-10-10-10zM12 20c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"></path>
          </symbol>
          <symbol id="c-svg--download-fill-24" viewBox="0 0 24 24">
            <path d="M12 1.992c-5.527 0-10.008 4.481-10.008 10.008s4.481 10.008 10.008 10.008 10.008-4.481 10.008-10.008-4.481-10.008-10.008-10.008zM16.707 13.707l-3.999 3.999c-0 0-0.001 0.001-0.001 0.001s-0.003 0.001-0.004 0.003c-0.091 0.091-0.2 0.163-0.321 0.213-0.122 0.051-0.252 0.077-0.383 0.077s-0.26-0.027-0.383-0.077c-0.112-0.046-0.21-0.116-0.297-0.197-0.008-0.008-0.020-0.010-0.028-0.019-0-0-0-0.001-0.001-0.001l-3.999-3.999c-0.391-0.391-0.391-1.024 0-1.414s1.024-0.39 1.414 0l2.293 2.293v-7.586c0-0.552 0.448-1 1-1s1 0.448 1 1v7.585l2.293-2.293c0.391-0.39 1.024-0.39 1.414 0s0.391 1.024 0 1.414z"></path>
          </symbol>
          <symbol id="c-svg--check-small-24" viewBox="0 0 24 24">
            <path d="M18.108 6.797c0.398-0.403 1.048-0.406 1.45-0.007 0.366 0.362 0.402 0.932 0.106 1.335l-0.099 0.116-8.874 8.963c-0.37 0.373-0.954 0.402-1.356 0.090l-0.115-0.104-4.799-5.037c-0.391-0.41-0.375-1.059 0.035-1.45 0.373-0.355 0.943-0.374 1.337-0.067l0.113 0.102 4.070 4.272 8.131-8.211z"></path>
          </symbol>
          <symbol id="c-svg--check-24" viewBox="0 0 24 24">
            <path d="M9 16.2l-4.2-4.2-1.4 1.4 5.6 5.6 12-12-1.4-1.4-10.6 10.6z"></path>
          </symbol>
          <symbol id="c-svg--check-circle-outline-24" viewBox="0 0 24 24">
            <path d="M16.59 7.58l-6.59 6.59-3.59-3.58-1.41 1.41 5 5 8-8zM12 2c-5.52 0-10 4.48-10 10s4.48 10 10 10 10-4.48 10-10-4.48-10-10-10zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"></path>
          </symbol>
          <symbol id="c-svg--check-circle-fill-24" viewBox="0 0 24 24">
            <path d="M12 2c-5.52 0-10 4.48-10 10s4.48 10 10 10 10-4.48 10-10-4.48-10-10-10zM10 17l-5-5 1.41-1.41 3.59 3.58 7.59-7.59 1.41 1.42-9 9z"></path>
          </symbol>
          <symbol id="c-svg--lock-open-24" viewBox="0 0 24 24">
            <path d="M12 17c1.1 0 2-0.9 2-2s-0.9-2-2-2-2 0.9-2 2 0.9 2 2 2zM18 8h-1v-2c0-2.76-2.24-5-5-5s-5 2.24-5 5h1.9c0-1.71 1.39-3.1 3.1-3.1s3.1 1.39 3.1 3.1v2h-9.1c-1.1 0-2 0.9-2 2v10c0 1.1 0.9 2 2 2h12c1.1 0 2-0.9 2-2v-10c0-1.1-0.9-2-2-2zM18 20h-12v-10h12v10z"></path>
          </symbol>
          <symbol id="c-svg--lock-closed-24" viewBox="0 0 24 24">
            <path d="M18 8h-1v-2c0-2.76-2.24-5-5-5s-5 2.24-5 5v2h-1c-1.1 0-2 0.9-2 2v10c0 1.1 0.9 2 2 2h12c1.1 0 2-0.9 2-2v-10c0-1.1-0.9-2-2-2zM12 17c-1.1 0-2-0.9-2-2s0.9-2 2-2 2 0.9 2 2-0.9 2-2 2zM15.1 8h-6.2v-2c0-1.71 1.39-3.1 3.1-3.1s3.1 1.39 3.1 3.1v2z"></path>
          </symbol>
          <symbol id="c-svg--search-24" viewBox="0 0 24 24">
            <path d="M15.5 14h-0.79l-0.28-0.27c0.98-1.14 1.57-2.62 1.57-4.23 0-3.59-2.91-6.5-6.5-6.5s-6.5 2.91-6.5 6.5 2.91 6.5 6.5 6.5c1.61 0 3.090-0.59 4.23-1.57l0.27 0.28v0.79l5 4.99 1.49-1.49-4.99-5zM9.5 14c-2.49 0-4.5-2.010-4.5-4.5s2.010-4.5 4.5-4.5 4.5 2.010 4.5 4.5-2.010 4.5-4.5 4.5z"></path>
          </symbol>
          <symbol id="c-svg--warning-24" viewBox="0 0 24 24">
            <path d="M12 17c1.1 0 2 0.9 2 2s-0.9 2-2 2-2-0.9-2-2 0.9-2 2-2zM10 3h4v12h-4v-12z"></path>
          </symbol>
          <symbol id="c-svg--warning-circle-outline-24" viewBox="0 0 24 24">
            <path d="M11 15h2v2h-2zM11 7h2v6h-2zM11.99 2c-5.52 0-9.99 4.48-9.99 10s4.47 10 9.99 10c5.53 0 10.010-4.48 10.010-10s-4.48-10-10.010-10zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"></path>
          </symbol>
          <symbol id="c-svg--warning-fill-24" viewBox="0 0 24 24">
            <path d="M12 2c-5.52 0-10 4.48-10 10s4.48 10 10 10 10-4.48 10-10-4.48-10-10-10zM13 17h-2v-2h2v2zM13 13h-2v-6h2v6z"></path>
          </symbol>
          <symbol id="c-svg--remove-24" viewBox="0 0 24 24">
            <path d="M4.82 11h14.36v2h-14.36v-2z"></path>
          </symbol>
          <symbol id="c-svg--add-24" viewBox="0 0 24 24">
            <path d="M11 4.82v6.18h-6.18v2h6.18v6.18h2v-6.18h6.18v-2h-6.18v-6.18z"></path>
          </symbol>
          <symbol id="c-svg--add-circle-outline-24" viewBox="0 0 24 24">
            <path d="M17 11h-4v-4h-2v4h-4v2h4v4h2v-4h4v-2zM12 2c-5.52 0-10 4.48-10 10s4.48 10 10 10 10-4.48 10-10-4.48-10-10-10zM12 20c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"></path>
          </symbol>
          <symbol id="c-svg--add-circle-fill-24" viewBox="0 0 24 24">
            <path d="M12 1.992c-5.527 0-10.008 4.481-10.008 10.008s4.481 10.008 10.008 10.008 10.008-4.481 10.008-10.008c0-5.527-4.481-10.008-10.008-10.008zM17 13h-4v4h-2v-4h-4v-2h4v-4h2v4h4v2z"></path>
          </symbol>
          <symbol id="c-svg--arrow-indent-24" viewBox="0 0 24 24">
            <path d="M14.302 6.3l-1.4 1.4 3.3 3.3h-10.202v-9h-2v11h12.202l-3.3 3.3 1.4 1.4 5.7-5.7z"></path>
          </symbol>
          <symbol id="c-svg--arrow-indent-thin-24" viewBox="0 0 24 24">
            <path d="M14.7 6.1l-0.8 0.7 4.2 4.2h-13.1v-9h-1v10h14.1l-4.2 4.1 0.8 0.7 5.3-5.3z"></path>
          </symbol>
          <symbol id="c-svg--close-24" viewBox="0 0 24 24">
            <path d="M16.369 6.216l-4.37 4.37-4.37-4.37-1.414 1.414 4.37 4.37-4.37 4.37 1.414 1.414 4.37-4.37 4.37 4.37 1.414-1.414-4.37-4.37 4.37-4.37z"></path>
          </symbol>
          <symbol id="c-svg--clear-24" viewBox="0 0 24 24">
            <path d="M18.369 4.216l-6.37 6.37-6.37-6.37-1.414 1.414 6.37 6.37-6.37 6.37 1.414 1.414 6.37-6.37 6.37 6.37 1.414-1.414-6.37-6.37 6.37-6.37z"></path>
          </symbol>
          <symbol id="c-svg--clear-circle-outline-24" viewBox="0 0 24 24">
            <path d="M14.828 7.757l-2.828 2.828-2.828-2.828-1.414 1.414 2.828 2.828-2.828 2.828 1.414 1.414 2.828-2.828 2.828 2.828 1.414-1.414-2.828-2.828 2.828-2.828-1.414-1.414zM12 2c-5.52 0-10 4.48-10 10s4.48 10 10 10 10-4.48 10-10-4.48-10-10-10zM12 20c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"></path>
          </symbol>
          <symbol id="c-svg--clear-circle-fill-24" viewBox="0 0 24 24">
            <path d="M12 1.992c-5.527 0-10.008 4.481-10.008 10.008s4.481 10.008 10.008 10.008 10.008-4.481 10.008-10.008c0-5.527-4.481-10.008-10.008-10.008zM16.242 14.828l-1.414 1.414-2.828-2.828-2.828 2.828-1.414-1.414 2.828-2.828-2.828-2.828 1.414-1.414 2.828 2.828 2.828-2.828 1.414 1.414-2.828 2.828 2.828 2.828z"></path>
          </symbol>
          <symbol id="c-svg--email-24" viewBox="0 0 24 24">
            <path d="M12 11.18l10-5.6c-0.2-0.91-1-1.58-1.956-1.58-0.016 0-0.031 0-0.047 0.001h-15.998c-0.007 0-0.015 0-0.023 0-0.999 0-1.826 0.732-1.976 1.689l-0.001 0.011 10.001 5.479zM12 13.47l-10-5.47v10c0 1.105 0.895 2 2 2v0h16c1.105 0 2-0.895 2-2v0-10.15l-10 5.62z"></path>
          </symbol>
          <symbol id="c-svg--info-circle-outline-24" viewBox="0 0 24 24">
            <path d="M14.085 16.913h-4.012v-0.463c0.11-0.008 0.218-0.019 0.324-0.032s0.197-0.034 0.273-0.063c0.135-0.051 0.23-0.124 0.286-0.219s0.083-0.221 0.083-0.378v-3.694c0-0.148-0.034-0.278-0.102-0.391s-0.152-0.202-0.254-0.27c-0.076-0.051-0.191-0.1-0.346-0.146s-0.295-0.076-0.422-0.089v-0.463l3.11-0.165 0.095 0.095v5.040c0 0.148 0.032 0.274 0.095 0.377s0.154 0.181 0.273 0.232c0.084 0.038 0.178 0.072 0.279 0.102s0.208 0.051 0.317 0.063v0.463l0.001 0.001zM13.158 8.255c0 0.322-0.124 0.596-0.371 0.822s-0.541 0.339-0.879 0.339c-0.343 0-0.638-0.113-0.885-0.339s-0.372-0.5-0.372-0.822 0.124-0.597 0.372-0.825c0.247-0.229 0.542-0.343 0.885-0.343s0.637 0.114 0.882 0.343 0.368 0.503 0.368 0.825zM12 2c-5.52 0-10 4.48-10 10s4.48 10 10 10 10-4.48 10-10-4.48-10-10-10zM12 20c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"></path>
          </symbol>
          <symbol id="c-svg--info-circle-fill-24" viewBox="0 0 24 24">
            <path d="M12 1.992c-5.527 0-10.008 4.481-10.008 10.008s4.481 10.008 10.008 10.008 10.008-4.481 10.008-10.008-4.481-10.008-10.008-10.008zM11.023 7.43c0.247-0.229 0.542-0.343 0.885-0.343s0.637 0.114 0.882 0.343 0.368 0.503 0.368 0.825-0.124 0.596-0.371 0.822c-0.248 0.226-0.541 0.339-0.879 0.339-0.343 0-0.638-0.113-0.885-0.339s-0.372-0.5-0.372-0.822 0.124-0.597 0.372-0.825zM14.085 16.913h-4.012v-0.463c0.11-0.008 0.218-0.019 0.324-0.032s0.197-0.034 0.273-0.063c0.135-0.051 0.23-0.124 0.286-0.219s0.083-0.221 0.083-0.378v-3.694c0-0.148-0.034-0.278-0.102-0.391s-0.152-0.202-0.254-0.27c-0.076-0.051-0.191-0.1-0.346-0.146s-0.295-0.076-0.422-0.089v-0.463l3.11-0.165 0.095 0.095v5.040c0 0.148 0.032 0.274 0.095 0.377s0.154 0.181 0.273 0.232c0.084 0.038 0.178 0.072 0.279 0.102s0.208 0.051 0.317 0.063v0.463z"></path>
          </symbol>
          <symbol id="c-svg--help-circle-outline-24" viewBox="0 0 24 24">
            <path d="M8.336 9.23c0-0.586 0.088-1.12 0.264-1.6s0.432-0.891 0.768-1.232 0.741-0.605 1.216-0.792c0.474-0.187 1.005-0.28 1.592-0.28 0.49 0 0.946 0.072 1.368 0.216s0.789 0.352 1.104 0.624c0.314 0.272 0.563 0.611 0.744 1.016s0.272 0.864 0.272 1.376c0 0.374-0.040 0.696-0.12 0.968s-0.187 0.507-0.32 0.704-0.285 0.374-0.456 0.528c-0.17 0.155-0.341 0.307-0.512 0.456-0.203 0.171-0.39 0.336-0.56 0.496s-0.32 0.339-0.448 0.536c-0.128 0.197-0.227 0.429-0.296 0.696s-0.104 0.592-0.104 0.976h-1.728c0-0.469 0.024-0.867 0.072-1.192s0.125-0.611 0.232-0.856c0.106-0.246 0.237-0.461 0.392-0.648s0.338-0.371 0.552-0.552c0.17-0.149 0.333-0.288 0.488-0.416s0.293-0.269 0.416-0.424c0.122-0.155 0.218-0.328 0.288-0.52s0.104-0.421 0.104-0.688c0-0.32-0.056-0.595-0.168-0.824s-0.248-0.413-0.408-0.552-0.331-0.24-0.512-0.304-0.341-0.096-0.48-0.096c-0.662 0-1.149 0.216-1.464 0.648s-0.472 1.011-0.472 1.736c0 0-1.824 0-1.824 0zM10.993 16h2.031v2h-2.031v-2zM12 2c-5.52 0-10 4.48-10 10s4.48 10 10 10 10-4.48 10-10-4.48-10-10-10zM12 20c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"></path>
          </symbol>
          <symbol id="c-svg--help-circle-fill-24" viewBox="0 0 24 24">
            <path d="M12 1.992c-5.527 0-10.008 4.481-10.008 10.008s4.481 10.008 10.008 10.008 10.008-4.481 10.008-10.008c0-5.527-4.481-10.008-10.008-10.008zM13.024 18h-2.031v-2h2.031v2zM15.544 9.526c-0.080 0.272-0.187 0.507-0.32 0.704s-0.285 0.374-0.456 0.528c-0.17 0.155-0.341 0.307-0.512 0.456-0.203 0.171-0.39 0.336-0.56 0.496s-0.32 0.339-0.448 0.536c-0.128 0.197-0.227 0.429-0.296 0.696s-0.104 0.592-0.104 0.976h-1.728c0-0.469 0.024-0.867 0.072-1.192s0.125-0.611 0.232-0.856c0.106-0.246 0.237-0.461 0.392-0.648s0.338-0.371 0.552-0.552c0.17-0.149 0.333-0.288 0.488-0.416s0.293-0.269 0.416-0.424c0.122-0.155 0.218-0.328 0.288-0.52s0.104-0.421 0.104-0.688c0-0.32-0.056-0.595-0.168-0.824s-0.248-0.413-0.408-0.552c-0.16-0.139-0.331-0.24-0.512-0.304s-0.341-0.096-0.48-0.096c-0.662 0-1.149 0.216-1.464 0.648s-0.472 1.011-0.472 1.736h-1.824c0-0.586 0.088-1.12 0.264-1.6s0.432-0.891 0.768-1.232 0.741-0.605 1.216-0.792c0.474-0.187 1.005-0.28 1.592-0.28 0.49 0 0.946 0.072 1.368 0.216s0.789 0.352 1.104 0.624c0.314 0.272 0.563 0.611 0.744 1.016s0.272 0.864 0.272 1.376c0 0.374-0.040 0.696-0.12 0.968z"></path>
          </symbol>
          <symbol id="c-svg--mask-24" viewBox="0 0 24 24">
            <path d="M19.936 11.688c-0.886-2.694-4.039-9.687-7.937-9.687-3.872 0-7.050 6.99-7.946 9.676-0.932 2.724-0.403 5.031 1.499 6.218 1.456 0.728 2.31 0.975 3.697 1.060v0.938c0 1.382 0.239 1.818 1.73 1.857 0.236 0.006 0.489 0.009 0.738 0.009 0.271 0 0.538-0.002 0.777-0.005l0.505-0.003c1.509 0 1.75-0.432 1.75-1.817v-0.978c1.388-0.085 2.241-0.332 3.697-1.060 2.859-1.429 2.445-3.389 1.489-6.207zM10.75 16c0.005-1.044-0.751-1.74-0.75-2.677-0.018-0.38-0.001-0.97 1.999-0.97s2.001 0.633 2.001 0.95c-0.048 0.903-0.75 1.674-0.75 2.698 0 1.333 0 2.992-0.001 4.249l-2.497-0.007-0.002-4.242zM17.552 16.105c-1.118 0.559-1.705 0.769-2.803 0.847v-0.952c0-0.365 0.254-0.9 0.478-1.373 0.269-0.566 0.522-1.102 0.522-1.627 0-1.78-2.527-2.092-3.746-2.092-2.484 0-3.754 0.911-3.754 2.092 0 0.466 0.229 0.959 0.495 1.529 0.225 0.484 0.505 1.086 0.505 1.471v0.952c-1.070-0.076-1.665-0.276-2.719-0.8-1.447-0.908-0.813-3.16-0.582-3.836 1.133-3.399 3.939-8.316 6.051-8.316 2.047 0 4.895 4.841 6.040 8.321 1.026 3.027 0.706 3.188-0.487 3.784z"></path>
          </symbol>
          <symbol id="c-svg--launch-24" viewBox="0 0 24 24">
            <path d="M19 19h-14v-14h7v-2h-7c-1.11 0-2 0.9-2 2v14c0 1.1 0.89 2 2 2h14c1.1 0 2-0.9 2-2v-7h-2v7zM14 3v2h3.59l-9.83 9.83 1.41 1.41 9.83-9.83v3.59h2v-7h-7z"></path>
          </symbol>
          <symbol id="c-svg--document-generic-24" viewBox="0 0 24 24">
            <path d="M15 2h-9c-1.105 0-2 0.895-2 2v0 16c0 1.105 0.895 2 2 2v0h12c1.105 0 2-0.895 2-2v0-13l-5-5zM6 20v-16h8v4h4v12h-12z"></path>
          </symbol>
          <symbol id="c-svg--profile-circle-outline-24" viewBox="0 0 24 24">
            <path d="M13.526 13.3c1.355-0.591 2.306-1.94 2.306-3.51 0-2.112-1.719-3.831-3.832-3.831s-3.832 1.719-3.832 3.831c0 1.57 0.951 2.919 2.306 3.51-1.869 0.529-3.225 1.979-3.225 3.7h1.5c0-1.338 1.458-2.426 3.251-2.426s3.25 1.088 3.25 2.426h1.5c0-1.721-1.356-3.171-3.225-3.7zM9.668 9.79c0-1.285 1.046-2.331 2.332-2.331s2.332 1.046 2.332 2.331c0 1.286-1.046 2.332-2.332 2.332s-2.332-1.046-2.332-2.332zM12 2c-5.52 0-10 4.48-10 10s4.48 10 10 10 10-4.48 10-10-4.48-10-10-10zM12 20c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"></path>
          </symbol>
          <symbol id="c-svg--profile-circle-fill-24" viewBox="0 0 24 24">
            <path d="M14.332 9.79c0-1.285-1.046-2.331-2.332-2.331s-2.332 1.046-2.332 2.331c0 1.286 1.046 2.332 2.332 2.332s2.332-1.046 2.332-2.332z"></path>
            <path d="M12 2c-5.52 0-10 4.48-10 10s4.48 10 10 10 10-4.48 10-10c0-5.52-4.48-10-10-10zM15.25 17c0-1.338-1.458-2.426-3.25-2.426s-3.25 1.088-3.25 2.426h-1.5c0-1.721 1.356-3.171 3.225-3.7-1.355-0.591-2.306-1.94-2.306-3.51 0-2.112 1.719-3.831 3.832-3.831s3.832 1.719 3.832 3.831c0 1.57-0.951 2.919-2.306 3.51 1.869 0.529 3.225 1.979 3.225 3.7h-1.5z"></path>
          </symbol>
          <symbol id="c-svg--dot-24" viewBox="0 0 24 24">
            <path d="M12 2c-5.52 0-10 4.48-10 10s4.48 10 10 10 10-4.48 10-10-4.48-10-10-10z"></path>
          </symbol>
          <symbol id="c-svg--delete-fill-24" viewBox="0 0 24 24">
            <path d="M6 19c0 1.1 0.9 2 2 2h8c1.1 0 2-0.9 2-2v-12h-12v12zM19 4h-3.5l-1-1h-5l-1 1h-3.5v2h14v-2z"></path>
          </symbol>
          <symbol id="c-svg--delete-outline-24" viewBox="0 0 24 24">
            <path d="M16 9v10h-8v-10h8zM14.5 3h-5l-1 1h-3.5v2h14v-2h-3.5l-1-1zM18 7h-12v12c0 1.1 0.9 2 2 2h8c1.1 0 2-0.9 2-2v-12z"></path>
          </symbol>
          <symbol id="c-svg--timer-filled-24" viewBox="0 0 24 24">
            <path d="M15 3h-6v-2h6v2zM21 13c0 5-4 9-9 9s-9-4-9-9 4-9 9-9c2.1 0 4.1 0.7 5.6 2l1.4-1.4c0.5 0.4 1 0.9 1.5 1.4l-1.5 1.4c1.3 1.5 2 3.5 2 5.6zM13 8h-2v6h2v-6z"></path>
          </symbol>
          <symbol id="c-svg--timer-24" viewBox="0 0 24 24">
            <path d="M15 1h-6v2h6v-2zM11 14h2v-6h-2v6zM19.030 7.39l1.42-1.42c-0.43-0.51-0.9-0.99-1.41-1.41l-1.42 1.42c-1.55-1.24-3.5-1.98-5.62-1.98-4.97 0-9 4.030-9 9s4.020 9 9 9 9-4.030 9-9c0-2.12-0.74-4.070-1.97-5.61zM12 20c-3.87 0-7-3.13-7-7s3.13-7 7-7 7 3.13 7 7-3.13 7-7 7z"></path>
          </symbol>
          <symbol id="c-svg--location-on-24" viewBox="0 0 24 24">
            <path d="M12 2c-3.87 0-7 3.13-7 7 0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zM12 11.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z"></path>
          </symbol>
          <symbol id="c-svg--place-outline-24" viewBox="0 0 24 24">
            <path d="M12 12c-1.1 0-2-0.9-2-2s0.9-2 2-2 2 0.9 2 2-0.9 2-2 2zM18 10.2c0-3.63-2.65-6.2-6-6.2s-6 2.57-6 6.2c0 2.34 1.95 5.44 6 9.14 4.050-3.7 6-6.8 6-9.14zM12 2c4.2 0 8 3.22 8 8.2 0 3.32-2.67 7.25-8 11.8-5.33-4.55-8-8.48-8-11.8 0-4.98 3.8-8.2 8-8.2z"></path>
          </symbol>
          <symbol id="c-svg--language-24" viewBox="0 0 24 24">
            <path d="M7.6 6.9l1.2 3.1h-2.4l1.2-3.1zM22 4v18l-4-4h-14c-1.1 0-2-0.9-2-2v-12c0-1.1 0.9-2 2-2h16c1.1 0 2 0.9 2 2zM11.6 13.6l-3.3-8.7h-1.4l-3.3 8.7h1.4l0.8-2.2h3.5l0.8 2.2h1.5zM20.6 6.6h-4.3v-1.3h-1.3v1.2h-4.3v1.2h6.9c-0.4 1.2-1.1 2.3-2 3.3-0.6-0.6-1-1.3-1.4-2.1h-1.2c0.4 1 1.1 2 1.8 2.8l-3.1 3.1 0.9 0.9 3.1-3.1 1.9 1.9 0.5-1.3-1.6-1.5c1.1-1.2 1.8-2.6 2.3-4h1.8v-1.1z"></path>
          </symbol>
          <symbol id="c-svg--language-outline-24" viewBox="0 0 24 24">
            <path d="M2 4v12c0 1.1 0.9 2 2 2h14l4 4v-18c0-1.1-0.9-2-2-2h-16c-1.1 0-2 0.9-2 2zM4 4h16v14l-2-2h-14v-12zM18.9 7.3h-3.4v-1h-1v1h-3.5v1h5.5c-0.3 1-0.9 1.8-1.6 2.6-0.5-0.5-0.8-1-1.1-1.7h-1c0.3 0.8 0.9 1.6 1.4 2.2l-2.5 2.5 0.7 0.7 2.5-2.5 1.5 1.5 0.4-1-1.3-1.2c0.9-1 1.4-2.1 1.8-3.2h1.4c0.2 0 0.2-0.9 0.2-0.9zM9.1 6h-1.2l-2.6 7h1.1l0.6-1.8h2.8l0.6 1.8h1.2l-2.5-7zM7.5 10.1l1-2.5 1 2.5h-2z"></path>
          </symbol>
          <symbol id="c-svg--email-outline-24" viewBox="0 0 24 24">
            <path d="M20 4h-16c-1.1 0-1.99 0.9-1.99 2l-0.010 12c0 1.1 0.9 2 2 2h16c1.1 0 2-0.9 2-2v-12c0-1.1-0.9-2-2-2zM20 18h-16v-10l8 5 8-5v10zM12 11l-8-5h16l-8 5z"></path>
          </symbol>
          <symbol id="c-svg--circle-outline-24" viewBox="0 0 24 24">
            <path d="M12 2c-5.5 0-10 4.5-10 10s4.5 10 10 10 10-4.5 10-10-4.5-10-10-10zM12 20c-4.4 0-8-3.6-8-8s3.6-8 8-8 8 3.6 8 8-3.6 8-8 8z"></path>
          </symbol>
          <symbol id="c-svg--square-filled-24" viewBox="0 0 24 24">
            <path d="M20 4h-16v16h16v-16z"></path>
          </symbol>
          <symbol id="c-svg--square-outline-24" viewBox="0 0 24 24">
            <path d="M20 4h-16v16h16v-16zM18 18h-12v-12h12v12z"></path>
          </symbol>
          <symbol id="c-svg--triangle-filled-24" viewBox="0 0 24 24">
            <path d="M12 4l-10 16h20l-10-16z"></path>
          </symbol>
          <symbol id="c-svg--triangle-outline-24" viewBox="0 0 24 24">
            <path d="M12 7.77l6.39 10.23h-12.78l6.39-10.23zM12 4l-10 16h20l-10-16z"></path>
          </symbol>
          <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="c-svg c-svg--thumb-down-24" style="fill: #b92025">
              <path d="M15 3h-9c-0.83 0-1.54 0.5-1.84 1.22l-3.020 7.050c-0.090 0.23-0.14 0.47-0.14 0.73v2c0 1.1 0.9 2 2 2h6.31l-0.95 4.57-0.030 0.32c0 0.41 0.17 0.79 0.44 1.060l1.060 1.050 6.59-6.59c0.36-0.36 0.58-0.86 0.58-1.41v-10c0-1.1-0.9-2-2-2zM19 3v12h4v-12h-4z"></path>
          </symbol>
          <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="c-svg c-svg--thumb-up-24" style="fill: #1788c3">
            <path d="M1 21h4v-12h-4v12zM23 10c0-1.1-0.9-2-2-2h-6.31l0.95-4.57 0.030-0.32c0-0.41-0.17-0.79-0.44-1.060l-1.060-1.050-6.58 6.59c-0.37 0.36-0.59 0.86-0.59 1.41v10c0 1.1 0.9 2 2 2h9c0.83 0 1.54-0.5 1.84-1.22l3.020-7.050c0.090-0.23 0.14-0.47 0.14-0.73v-2z"></path>
          </symbol>
      </defs>
    </svg>
  </ng-template>