<div id="MquiModal" class="modal">
  <div class="modal-content">
    <div>
      <span class="close" (click)="closeModal('MquiModal', [])">&times;</span>
    </div>
    <iframe
      *ngIf="mquiUrl"
      [src]="mquiUrl"
      title="MQUI"
      id="iframe-mqui-embedded"
      scrolling="no"
    >
      Your browser does not support iframes.
    </iframe>
  </div>
</div>
