import { PlatformLocation } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { ElementRef } from '@angular/core';

export function sbxUriBuilder(
  uri: string,
  platformLocation: PlatformLocation
): string {
  const regex = /^https:\/\/(sc-\d{4})/;

  const prefix = platformLocation.href.match(regex);
  if (prefix) {
    uri = uri.replace('stackPrefix', prefix[1]);
  }
  return uri;
}

export function copyToClipboard(text: string | number) {
  const textToCopy = text.toString();
  navigator.clipboard.writeText(textToCopy).then(
    () => {
      console.log('Text copied to clipboard');
    },
    (err) => {
      console.error('Failed to copy text: ', err);
    }
  );
}

export function getFormattedDate(dateTime: string): string {
  const date = new Date(dateTime);
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
}

// Check which prefix applies to surveyID supplied, return built query string
export function buildQueryString(sourceID: string): string {
  let queryString = '';

  if (sourceID.indexOf('#') >= 0) {
    return sourceID;
  }
  if (sourceID.length == 11) {
    queryString = 'DEVICE_SERIAL#' + sourceID;
  } else if (isValidUUID(sourceID)) {
    queryString = 'PATIENT_ECN#' + sourceID;
  } else {
    queryString = 'PATIENT_ID#' + sourceID;
  }

  return queryString;
}

//Check if string is UUID v1 or v4
export function isValidUUID(str: string): boolean {
  const uuidRegexV1 =
    /^[0-9a-f]{8}-[0-9a-f]{4}-1[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
  const uuidRegexV4 =
    /^[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;

  return uuidRegexV1.test(str) || uuidRegexV4.test(str);
}

export function queryErrShow(elementRef: ElementRef): void {
  const warn = elementRef.nativeElement.querySelector('#query-err');
  console.log(warn);
  warn.classList.add('show');
  setTimeout(() => {
    warn.classList.remove('show');
  }, 2000);
}

export async function switchboardCall(
  payload: any,
  uri: string,
  platformLocation: PlatformLocation,
  httpClient: HttpClient
): Promise<any> {
  if (uri.indexOf('honeybadgers-dev') >= 0) {
    //If the build environment is sandbox, get the branch prefix from URL and replace place holder
    uri = sbxUriBuilder(uri, platformLocation);
  }

  try {
    const response = await httpClient.post<any>(uri, payload).toPromise();
    console.log('Response: ', response);
    return response;
  } catch (error) {
    throw error;
  }
}
