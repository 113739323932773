import { Inject, Injectable } from '@angular/core';
import { OKTA_AUTH } from '@okta/okta-angular';
import OktaAuth from '@okta/okta-auth-js';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  public name: string | undefined = undefined;
  constructor(@Inject(OKTA_AUTH) private oktaAuth: OktaAuth) { }

  logout(): void {
    this.oktaAuth.signOut();
  }
}
