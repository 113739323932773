import { Component, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';

@Component({
  selector: 'app-ads-svg-symbols',
  templateUrl: './svg-symbols.component.html',
  styleUrls: ['./svg-symbols.component.scss']
})
export class SvgSymbolsComponent implements OnInit {
  @ViewChild('adsSvgSymbolsComponent', {static: true}) adsSvgSymbolsComponent!: TemplateRef<any>;

  constructor(
    private viewContainerRef: ViewContainerRef
  ) { }

  ngOnInit(): void {
    this.viewContainerRef.createEmbeddedView(this.adsSvgSymbolsComponent);
    this.viewContainerRef.element.nativeElement.remove();
  }

}