//Supported countries and languages for hme_consent_change
export const countries = [
  { name: 'Argentina', code: 'AR' },
  { name: 'Australia', code: 'AU' },
  { name: 'Brazil', code: 'BR' },
  { name: 'Canada', code: 'CA' },
  { name: 'Chile', code: 'CL' },
  { name: 'Colombia', code: 'CO' },
  { name: 'Hong Kong', code: 'HK' },
  { name: 'India', code: 'IN' },
  { name: 'Japan', code: 'JP' },
  { name: 'Malaysia', code: 'MY' },
  { name: 'Mexico', code: 'MX' },
  { name: 'New Zealand', code: 'NZ' },
  { name: 'Philippines', code: 'PH' },
  { name: 'Singapore', code: 'SG' },
  { name: 'South Korea', code: 'KR' },
  { name: 'Taiwan', code: 'TW' },
  { name: 'Thailand', code: 'TH' },
  { name: 'United States', code: 'US' },
];
export const languages = [
  { language: 'Arabic', locale: 'ar' },
  { language: 'Bulgarian', locale: 'bg' },
  { language: 'Chinese Simplified', locale: 'zh-hans' },
  { language: 'Chinese Traditional', locale: 'zh' },
  { language: 'Croatian', locale: 'hr' },
  { language: 'Czech', locale: 'cs' },
  { language: 'Danish', locale: 'da' },
  { language: 'Dutch', locale: 'nl' },
  { language: 'English', locale: 'en' },
  { language: 'English US', locale: 'en-US' },
  { language: 'Estonian', locale: 'et' },
  { language: 'Finnish', locale: 'fi' },
  { language: 'French', locale: 'fr' },
  { language: 'German', locale: 'de' },
  { language: 'Greek', locale: 'el' },
  { language: 'Hungarian', locale: 'hu' },
  { language: 'Icelandic', locale: 'is' },
  { language: 'Italian', locale: 'it' },
  { language: 'Japanese', locale: 'ja' },
  { language: 'Korean', locale: 'ko' },
  { language: 'Latvian', locale: 'lv' },
  { language: 'Lithuanian', locale: 'lt' },
  { language: 'Norwegian', locale: 'nb' },
  { language: 'Polish', locale: 'pl' },
  { language: 'Portuguese (Brazil)', locale: 'pt-BR' },
  { language: 'Portuguese (Portugal)', locale: 'pt' },
  { language: 'Romanian', locale: 'ro' },
  { language: 'Russian', locale: 'ru' },
  { language: 'Slovenian', locale: 'sl' },
  { language: 'Spanish', locale: 'es' },
  { language: 'Spanish (Latin America)', locale: 'es-419' },
  { language: 'Swedish', locale: 'sv' },
  { language: 'Thai', locale: 'th' },
  { language: 'Turkish', locale: 'tr' },
];

//Supported Surveys
export const surveys = [
  { name: 'Onboarding', id: 'subjective-feedback-v1' },
  { name: 'Sleepiness', id: 'sleep-concierge-v1' },
  { name: 'Sleep Quality', id: 'sleep-quality-v1' },
  { name: 'Mask Confirmation', id: 'mask-confirmation-v1' },
  { name: 'Mask Sense', id: 'mask-sense-v1' },
];
