<!-- Alert messages -->
<div *ngIf="submitStatus" class="modal-success">Success!</div>

<div id="triggerSurveyModal" class="modal">
  <div class="modal-content">
    <div>
      <span
        class="close"
        (click)="
          closeModal('triggerSurveyModal', [
            'survey-dropdown',
            'patient-id-trigger_survey',
            'day-offset-trigger_survey',
            'device-serial-trigger_survey'
          ])
        "
        >&times;</span
      >
      <h1>Trigger Survey</h1>
    </div>

    <div class="survey-fields">
      <div class="select">
        <p><strong>Select Survey</strong></p>
        <select
          id="survey-dropdown"
          class="site-nav__dropdown"
          [(ngModel)]="selectedSurveyId"
        >
          <option value="subjective-feedback-v1">
            Onboarding (subjective-feedback-v1)
          </option>
          <option value="sleep-concierge-v1">
            Sleepiness (sleep-concierge-v1)
          </option>
          <option value="sleep-quality-v1">
            Sleep Quality (sleep-quality-v1)
          </option>
          <option value="mask-confirmation-v1">
            Mask Confirmation (mask-confirmation-v1)
          </option>
          <option value="mask-sense-v1">Mask Sense (mask-sense-v1)</option>
        </select>
        <div id="surveyError" class="modal-error" [class.show]="missingSurvey">
          Please select a survey
        </div>
      </div>

      <div class="patient-id">
        <p><strong>Patient ID</strong></p>
        <input
          type="text"
          id="patient-id-trigger_survey"
          class="site-nav__textbox"
          [(ngModel)]="generatedPatientIDTrigger"
        />
      </div>

      <div class="day-offset">
        <p><strong>Day Offset</strong></p>
        <input
          type="text"
          id="day-offset-trigger_survey"
          class="site-nav__textbox"
          [(ngModel)]="dayOffset"
          placeholder="Day Offset"
        />
      </div>

      <div class="device-serial">
        <p><strong>Device Serial</strong></p>
        <input
          type="text"
          id="device-serial-trigger_survey"
          class="site-nav__textbox"
          [(ngModel)]="deviceSerial"
          placeholder="Device Serial"
        />
      </div>

      <button class="submit-btn" (click)="triggerSurvey()">
        <strong>Submit</strong>
      </button>
      <div *ngIf="triggerError" class="modal-submit-error">
        Error, Check Logs
      </div>
    </div>
  </div>
</div>
