import { Subject } from 'rxjs';
import { OnDestroy, Component } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';

@Component({ template: '' })
export abstract class BaseComponent implements OnDestroy {
  protected unsubscribe$ = new Subject<void>();

  constructor(public common: CommonService) {  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
