import { ElementRef } from '@angular/core';

declare var $: any;
export function openModal(
  id: string,
  inputIdToFill: string[],
  elementRef: ElementRef
): void {
  const modal = elementRef.nativeElement.querySelector(
    `#${id}`
  ) as HTMLDivElement;

  if (inputIdToFill.length > 0) populateTextInputs(id, inputIdToFill);

  modal.style.display = 'block';
}

export function closeModal(
  id: string,
  drpdownIds: string[],
  elementRef: ElementRef
): void {
  const modal = elementRef.nativeElement.querySelector(
    `#${id}`
  ) as HTMLDivElement;
  modal.style.display = 'none';

  if (modal) {
    // Find all input elements of type text within the modal
    drpdownIds.forEach((id) => {
      const input = modal.querySelector(`#${id}`);
      if (input && input instanceof HTMLInputElement && input.type === 'text') {
        input.value = '';
      }
    });
  }

  resetSelect2Dropdowns(drpdownIds);
}

export function populateTextInputs(modalId: string, idsToPopulate: string[]) {
  const modal = document.getElementById(modalId);

  if (modal) {
    // Iterate through specified IDs and populate with random values
    idsToPopulate.forEach((id) => {
      const input = modal.querySelector(`#${id}`);
      if (
        input &&
        input instanceof HTMLInputElement &&
        input.type === 'text' &&
        id.includes('source')
      ) {
        input.value = 'DEVICE_SERIAL#' + generateRand(8);
      } else if (
        input &&
        input instanceof HTMLInputElement &&
        input.type === 'text'
      ) {
        input.value = generateRand(8);
      }
    });
  }
}

export function generateRand(length: number): string {
  let max = 10 ** length;
  return Math.floor(Math.random() * max).toString();
}

export function resetSelect2Dropdowns(ids: string[]): void {
  ids.forEach((id) => {
    $(`#${id}`).val(null).trigger('change');
  });
}

export function checkEmptyInputs(
  inputIds: string[],
  triggerFieldError: boolean
): void {
  for (let inputId of inputIds) {
    let inputElement: HTMLInputElement | null = document.getElementById(
      inputId
    ) as HTMLInputElement;

    if (inputElement && inputElement.value.trim() === '') {
      triggerFieldError = true;
      break;
    }
  }
}
