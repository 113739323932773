import { Inject, Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { OktaAuthStateService, OKTA_AUTH } from '@okta/okta-angular';
import OktaAuth, { AuthState } from '@okta/okta-auth-js';
import { filter, map, Observable } from 'rxjs';
import { CommonService } from './services/common.service';
import { BaseComponent } from './components/base/base.component';

@Injectable({
  providedIn: 'root',
})
export class OktaSignInGuard extends BaseComponent implements CanActivate {
  constructor(
    common: CommonService,
    private oktaStateService: OktaAuthStateService,
    @Inject(OKTA_AUTH) private oktaAuth: OktaAuth
  ) {
    super(common);
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.oktaStateService.authState$.pipe(
      filter((s: AuthState) => !!s),
      map((s: AuthState) => {
        if (!s.isAuthenticated) {
          this.oktaAuth.signInWithRedirect();
        } else {
          // Defensive coding, always set the name
          this.oktaStateService.authState$.subscribe((res: AuthState) => {
            this.common.name = res.idToken?.claims.name as string;
          });
        }
        return !!s.isAuthenticated;
      })
    );
  }
}
